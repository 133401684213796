import React from 'react'
import styled from 'styled-components'

const Btn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  width: ${(props) => props.width};
  font-size: 1.063rem;
  font-weight: ${(props) => props.weight};
  padding: 1rem;
  border: 0;
  border-radius: 5px;
`

function Button(props) {
  return <Btn {...props} />
}

export default Button

//Use of Button and Props
/* <div style={{ textAlign: "center" }}>
    <Button background="#e5e5ea" width="85%" color="red">
        Test
    </Button>
</div> */
