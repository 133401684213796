import { SwipeableDrawer } from '@material-ui/core'
import useUserData from 'hooks/useUserData'
import { useRouter } from 'next/router'
import { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import SubscriptionIcon from 'images/subscription.svg'
import ArmRedIcon from 'images/recommended-supplements.svg'
import PushRedIcon from 'images/notifications.svg'
import ProgramSelectionIcon from 'images/program-selection.svg'
import CustomIcon from 'images/customize.svg'
import ShareIcon from 'images/share.svg'
import FBIcon from 'images/facebook.svg'
import InfoRedIcon from 'images/contact.svg'
import LegalIcon from 'images/terms.svg'
import EdgeIcon from 'images/edge.svg'
import PrivacyIcon from 'images/privacy.svg'
import Button from 'components/Button'
import Link from 'next/link'
import { red } from '@material-ui/core/colors'
import { useFirebase } from '@sb-konzept/firebase-hooks'
import { StringFormat } from 'utils/firebase'
import Close from 'images/close.png'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Chooser from 'components/Chooser'
import Card from 'components/Card'
import ProgramIcon from 'images/your-program.svg'
import Fallback from 'images/fallback-avatar.jpg'

const ProfileImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 180px;
  width: 100%;
  margin-bottom: 0.3rem;
  flex-direction: column;
  padding: 1.5rem 0;
`

const BlurredPic = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url('${({ avatar }) => avatar}');
  background-position: center center;
  filter: blur(8px);
`

const ProfileImageCircle = styled.div`
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background: white;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 5px;
`

const Href = styled.a`
  text-decoration: none;
  font-size: 1.125rem;
  font-weight: 700;
  color: white;
  text-transform: capitalize;
  padding: 0.2rem 0;
  margin: 0 0 0 1rem;
  display: flex;
  align-items: center;
`

const Backdrop = styled.div`
  position: relative;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: ${({ open }) => (open ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
`

const Modal = styled(Card)`
  border: 1px solid #707070;
  position: relative;
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  padding-top: 2rem;
  background: black;
`

const CloseButton = styled.div`
  position: absolute;
  z-index: 1000;
  width: 1.5rem;
  height: 1.5rem;
  right: 10px;
  top: 10px;
  border-radius: 50%;
`

const MealButton = styled.button`
  font-size: 1.063rem;
  font-weight: 700;
  color: black;
  height: 2.813rem;
  background: #fff;
  margin: 0.3rem;
  border: 0px;
  border-radius: 10px;
`

export const DrawerContext = React.createContext({
  open: false,
  setOpen: () => {}
})

const StyledDrawer = styled(({ className, ...props }) => (
  <SwipeableDrawer classes={{ root: className }} {...props} />
))`
  .MuiBackdrop-root {
    background-color: rgba(48, 48, 48, 0.7);
    backdrop-filter: blur(4px);
  }
  .MuiDrawer-paper {
    width: 80%;
    overflow-x: hidden;
    background: rgba(0, 0, 0, 0.57);
    color: white;
    top: 1.5rem;
    height: calc(100% - 5rem);
  }
`

const Icon = styled.img`
  height: 30px;
  width: 30px;
  padding: 0.5rem;
`

export default function Drawer(props) {
  const firebase = useFirebase()
  const { children } = props
  const [open, setOpen] = useState(false)
  const [modal, setModal] = useState(false)
  const userData = useUserData()
  const router = useRouter()
  useEffect(() => {
    setOpen(false)
  }, [router.pathname])

  const value = useMemo(
    () => ({ open, setOpen, openPushModal: () => setModal(true) }),
    [open, setOpen, setModal]
  )

  return (
    <DrawerContext.Provider value={value}>
      {modal && (
        <Backdrop>
          <Modal>
            <Chooser
              color="white"
              label="Workout Reminder"
              options={['On', 'Off']}
              value={userData?.value?.workoutReminder ? 'On' : 'Off'}
              onChange={val =>
                userData.ref.update('workoutReminder', val === 'On')
              }
            />

            <Chooser
              color="white"
              label="Beastly Announcements"
              options={['On', 'Off']}
              value={userData?.value?.enablePush ? 'On' : 'Off'}
              onChange={val => userData.ref.update('enablePush', val === 'On')}
            />
            <MealButton onClick={() => setModal(false)}>Close</MealButton>
          </Modal>
        </Backdrop>
      )}
      {!['/signup', '/signup-form'].includes(router?.pathname) && (
        <StyledDrawer
          ModalProps={{ keepMounted: true }}
          disableSwipeToOpen={false}
          open={open}
          style={{ zIndex: 1500 }}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          <div
            style={{ position: 'absolute', left: 20, top: 20, zIndex: 1501 }}
            onClick={() => setOpen(false)}
          >
            <ChevronLeft />
          </div>
          <ProfileImageWrapper>
            <h1>&nbsp;</h1>
            <ProfileImageCircle>
              <img
                src={userData.value?.avatar || Fallback}
                style={{
                  filter: `brightness(${userData.value?.avatar ? 1 : 0.6})`,
                  objectFit: 'cover',
                  height: '100%',
                  width: '100%',
                  objectPosition: 'center'
                }}
              />
            </ProfileImageCircle>
            <h1 style={{ fontSize: '1.125rem' }}>
              {userData?.value?.firstname} {userData?.value?.lastname}
            </h1>
          </ProfileImageWrapper>
          <Link href="/program">
            <Href>
              <Icon src={ProgramIcon} />
              Your Program
            </Href>
          </Link>
          <Link href="/customize">
            <Href>
              <Icon src={CustomIcon} />
              Customize
            </Href>
          </Link>
          <Link href="/subscription">
            <Href>
              <Icon src={SubscriptionIcon} />
              Subscription
            </Href>
          </Link>
          <Link href="/experience">
            <Href>
              <Icon src={ProgramSelectionIcon} />
              Program Selection
            </Href>
          </Link>
          <Href
            onClick={() =>
              cordova.InAppBrowser.open('https://gains.club', '_system')
            }
          >
            <Icon src={ArmRedIcon} />
            Recommended Supplements
          </Href>
          <Href
            onClick={() =>
              cordova.InAppBrowser.open('https://edgelifestyle.com', '_system')
            }
          >
            <Icon src={EdgeIcon} />
            EDGE Lifestyle Clothing
          </Href>
          <hr />
          <Href
            onClick={() =>
              cordova.InAppBrowser.open(
                'https://www.facebook.com/groups/2653810471607412/',
                '_system'
              )
            }
          >
            <Icon src={FBIcon} />
            Facebook Support Group
          </Href>
          <div
            style={{
              margin: '0 0 0 1rem',
              padding: '0.2rem 0',
              fontSize: '1.125rem',
              color: 'white',
              fontWeight: 700,
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={() => setModal(true)}
          >
            <Icon src={PushRedIcon} />
            Push Notifications
          </div>
          <div
            style={{
              margin: '0 0 0 1rem',
              padding: '0.2rem 0',
              fontSize: '1.125rem',
              color: 'white',
              fontWeight: 700,
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={() =>
              window.plugins.socialsharing.shareWithOptions({
                subject: 'Thank 4 sharing HowtoBeast',
                files: [
                  'https://www.howtobeast.com/wp-content/uploads/2018/04/HTB-banner.png'
                ],
                url: 'https://beastlyapp.com/'
              })
            }
          >
            <Icon src={ShareIcon} />
            Share the App
          </div>
          <hr />
          <Link href="/contact">
            <Href>
              <Icon src={InfoRedIcon} />
              Contact us
            </Href>
          </Link>
          <Href
            onClick={() =>
              cordova.InAppBrowser.open(
                'https://www.beastlyapp.com/privacy/privacy.html',
                '_system'
              )
            }
          >
            <Icon src={PrivacyIcon} />
            Privacy Policy
          </Href>
          <Href
            onClick={() =>
              cordova.InAppBrowser.open(
                'https://www.beastlyapp.com/terms.html',
                '_system'
              )
            }
          >
            <Icon src={LegalIcon} />
            Terms and Conditions
          </Href>
        </StyledDrawer>
      )}
      {children}
    </DrawerContext.Provider>
  )
}
